import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:query_language?",
    name: "home",
    meta: {
      name: "Search Wildspecies.ca",
    },
    props: (route) => ({
      ...route.params,
    }),
    component: Home,
  },
  {
    path: "/:query_language?/species-profile/:pageTitle?/",
    name: "SpeciesProfile",
    props: (route) => ({
      ...route.params,
    }),
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SpeciesProfile.vue"),
  },
];

const router = new VueRouter({ routes });
router.afterEach((toRoute, fromRoute) => {
  window.document.title = toRoute?.params?.pageTitle
    ? `Wildspecies.ca - ${toRoute.params?.pageTitle} - ${toRoute.query.code} - ${toRoute.query.year}`
    : "Search - Wildspecies.ca";
});

export default router;
