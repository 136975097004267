import { rank_codes, years, origin, boolean_codes } from "./config";

export default {
  data: () => ({
    origin,
    boolean_codes,
  }),
  methods: {
    // THIS IS NOT CORRECT
    // mostRecentCommonName(mostRecentYear, sc_doc, language) {
    //   if (sc_doc && "ca_common_name" in sc_doc) {
    //     return sc_doc[`common_name_${language}`];
    //   }
    //   return mostRecentYear[`common_name_${language}`] || "";
    // },

    // This is the from the separate commons names database, not the rank table.
    currentCommonName(sc_doc, language) {
      return sc_doc && `common_name_${language}` in sc_doc
        ? sc_doc[`common_name_${language}`]
        : "";
    },

    // Is the given year considered historical? ie: pre 2015 when the schema changed
    isHistorical(year) {
      return years.find((item) => item.label == year).status;
    },

    // Return the text rank description for a given year, code and language

    rankTextYear(year, code, language) {
      const numYear = parseInt(year);
      const rankTxt = rank_codes[this.isHistorical(numYear)][code];
      return rankTxt ? rankTxt[`label_${language}`] : "";
    },

    // Return the rounded national rank for a given rank (applies to species current only)
    // param rank = ca_map_rank
    rankText(year, rank, language) {
      return rank_codes[this.isHistorical(year)][rank][`label_${language}`];
    },
    // Generate the natureServe URL for a given global ID
    ns_url(global_id) {
      return `https://explorer.natureserve.org/Taxon/ELEMENT_GLOBAL.${global_id}`;
    },
    titleCase(str) {
      str = str.toLowerCase().split(" ");
      for (var i = 0; i < str.length; i++) {
        str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
      }
      return str.join(" ");
    },
  },
};
