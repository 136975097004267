<template>
  <c-flex align="top" width="100%" :py="2">
    <c-grid
      :template-columns="['60px 16% 17% 16% 9% 1fr', '60px 29% 23% 19% 9% 1fr']"
      :gap="[1, 2]"
      width="100%"
    >
      <c-box>
        <c-text fontSize="l" color="green.400">{{ year.year }}</c-text>
      </c-box>
      <c-box>
        <c-link @click="navigate(year)">
          <c-text fontSize="md">{{ year[`common_name_${language}`] }}</c-text>
        </c-link>
      </c-box>
      <c-box>
        <c-link @click="navigate(year)">
          <c-text as="i" fontSize="md" color="gray.600">{{
            year.scientific_name
          }}</c-text>
        </c-link>
      </c-box>

      <c-box>
        <c-text fontSize="l">{{ year[`taxonomic_group_${language}`] }}</c-text>
      </c-box>

      <c-box width="100%" max-width="100%">
        <TextBadge
          :color="color(year[`${fieldPrefix}_map_rank`]).font_color"
          :bg="color(year[`${fieldPrefix}_map_rank`]).color"
          :rank="year[`${fieldPrefix}_rank`]"
        >
          {{ year[`${fieldPrefix}_rank`] }}
        </TextBadge>
      </c-box>

      <c-box>
        <c-text>{{ origin(year[`${fieldPrefix}_origin_code`]) }}</c-text>
      </c-box>
    </c-grid>
    <c-divider />
  </c-flex>
</template>

<script>
import {
  CBox,
  CText,
  // CStack,
  CFlex,
  // CBadge,
  CDivider,
} from "@chakra-ui/vue";

import RouterLink from "vue-router";
import { map_rank_color, years, origin } from "../config";
import { mapState, mapGetters } from "vuex";
import TextBadge from "./TextBadge.vue";

export default {
  name: "ResultItemYear",
  props: ["year", "status", "years"],
  components: {
    // eslint-disable-next-line
    RouterLink,
    CFlex,
    CBox,
    CText,
    TextBadge,
    CDivider,
  },
  data() {
    return {
      originCodes: origin,
    };
  },
  methods: {
    origin(value) {
      return value && this.originCodes[value][`label_${this.language}`];
    },

    color(value) {
      const item = map_rank_color[this.rowStatus][value];
      return item ? item : { color: "black", font_color: "white" };
    },
    navigate(data) {
      const common_name =
        data[`common_name_${this.language}`] || "no-common-name";
      this.$store.dispatch("setSelectedSpecies", this.years);
      this.$router.push({
        name: "SpeciesProfile",
        params: {
          query_language: this.language,
          pageTitle: common_name,
          year: data.year,
          code: data.code,
        },
        query: {
          code: data.code,
          year: data.year,
        },
      });
    },
  },
  computed: {
    ...mapState(["language", "selected_year", "selected_region"]),
    ...mapGetters(["fieldPrefix"]),
    rowStatus() {
      const rowYear = years.find((item) => item.label === this.year.year);
      const status = rowYear?.status;
      return status;
    },
  },
};
</script>

<style></style>
