<template>
  <c-flex
    align="top"
    width="100%"
    pb="1"
    border-bottom="1px solid #dbdbdb"
    :display="['none', 'none', 'flex']"
  >
    <c-box flexGrow="1">
      <c-grid :template-columns="['60px 28% 23% 19% 9% 1fr']" :gap="2">
        <c-box>
          <c-text fontSize="md" color="green.400">{{ label("year") }}</c-text>
        </c-box>
        <c-box>
          <c-text :fontSize="{ base: 'md' }">{{ label("species") }}</c-text>
        </c-box>
        <c-box>
          <c-text :fontSize="{ base: 'md' }">{{
            label("scientific_name")
          }}</c-text>
        </c-box>

        <c-box>
          <c-text :fontSize="{ base: 'md' }">{{
            label("taxonomic_group")
          }}</c-text>
        </c-box>
        <c-box pr="5">
          <c-text :fontSize="{ base: 'md' }">{{ label("rank") }}</c-text>
        </c-box>
        <c-box pr="5">
          <c-text :fontSize="{ base: 'md' }">{{ label("origin") }}</c-text>
        </c-box>
      </c-grid>
    </c-box>
  </c-flex>
</template>

<script>
import { labels } from "../config";
import { mapState } from "vuex";
export default {
  methods: {
    label(label) {
      return labels[label][this.language];
    },
  },
  computed: {
    ...mapState(["language"]),
  },
};
</script>
