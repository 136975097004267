<template>
  <CBox :mr="5">
    <CButton
      :display="['none', 'block']"
      variant="ghost"
      @click="getData"
      size="sm"
      :aria-disabled="fetchDisabled"
      :is-loading="fetching"
      loading-text="loading"
    >
      {{ label("download") }} CSV/Excel
    </CButton>
  </CBox>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { origin, labels, index_name } from "../config";

import algoliasearch from "algoliasearch";
import { createWidgetMixin } from "vue-instantsearch";
import { connectCurrentRefinements } from "instantsearch.js/es/connectors";

const index = algoliasearch(
  "X5NX4OTB3F",
  "74242f5e36cb6258a861c31f49ea3489"
).initIndex(index_name);

export default {
  name: "DownloadResults",
  mixins: [createWidgetMixin({ connector: connectCurrentRefinements })],
  data() {
    return {
      originCodes: origin,
      rowData: [],
      fetching: false,
      fetchDisabled: false,
    };
  },
  methods: {
    getData() {
      this.fetching = true;
      this.fetchDisabled = true;
      let hits = [];
      return index
        .browseObjects({
          filters: this.filters,
          query: this.state.instantSearchInstance.helper.state.query,
          batch: (batch) => {
            hits = hits.concat(batch);
          },
        })
        .then(() => {
          this.rowData = hits;
          this.createCsv();
          this.fetching = false;
          this.fetchDisabled = false;
        })
        .catch(() => {
          alert(
            "An error occured generating your results, please refine your query."
          );
        });
    },

    origin(value) {
      return value && this.originCodes[value][`label_${this.language}`];
    },

    createCsv() {
      this.exportCSVFile(this.headerRow, this.formattedData, "export");
    },

    convertToCSV(objArray) {
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";

      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }

        str += line + "\r\n";
      }

      return str;
    },

    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);

      var csv = this.convertToCSV(jsonObject);

      var exportedFilenmae = fileTitle + ".csv" || "export.csv";

      var blob = new Blob(["\uFEFF" + csv], {
        type: "text/csv;charset=utf-18",
      });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },
    label(label) {
      return labels[label][this.language];
    },
  },
  computed: {
    ...mapState(["language"]),
    ...mapGetters(["fieldPrefix"]),
    formattedData() {
      return this.rowData?.map((row) => ({
        year: row.year,
        code: row.code,
        common_name: row[`common_name_${this.language}`],
        scientific_name: row.scientific_name,
        taxonomic_group: row[`taxonomic_group_${this.language}`],
        rank: row[`${this.fieldPrefix}_rank`],
        origin: this.origin(row[`${this.fieldPrefix}_origin_code`]),
      }));
    },
    headerRow() {
      return {
        year: this.label("year"),
        code: this.label("species_code"),
        common_name: this.label("species"),
        scientific_name: this.label("scientific_name"),
        taxonomix_group: this.label("taxonomic_group"),
        rank: this.label("rank"),
        origin: this.label("origin"),
      };
    },

    filters() {
      if (!this.state) return {};
      return this.state.items
        ?.map(({ attribute, refinements }) => {
          return refinements
            .map(
              ({ attriube, value }) => `${attribute}:${JSON.stringify(value)}`
            )
            .join(" AND ");
        })
        .join(" AND ");
    },
  },
};
</script>

<style></style>
