import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Chakra from "@chakra-ui/vue";
import InstantSearch from "vue-instantsearch";
import DefaultLayout from "./layouts/Default.vue";

import { register } from "ol/proj/proj4";
import proj4 from "proj4";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-KGTVR5F3WQ" },
});

// taken fron http://epsg.io/
proj4.defs(
  "ESRI:102002",
  "+proj=lcc +lat_1=50 +lat_2=70 +lat_0=40 +lon_0=-96 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs"
);
register(proj4);

Vue.use(InstantSearch);
Vue.use(Chakra, {
  icons: {
    // Here we state that we use `fa`
    // icons library for Chakra's
    // internal icon parser
    iconPack: "fa",
    iconSet: {
      faBars,
      faTimes,
    },
  },
});

Vue.component("Layout", DefaultLayout);
import {
  Map,
  OsmSource,
  VectorLayer,
  VectorSource,
  StyleBox,
  StrokeStyle,
  FillStyle,
  TileLayer,
  StyleFunc,
  Overlay,
} from "vuelayers";
import "vuelayers/lib/style.css";

Vue.use(Map);
Vue.use(OsmSource);
Vue.use(VectorLayer);
Vue.use(VectorSource);
Vue.use(StrokeStyle);
Vue.use(StyleBox);
Vue.use(FillStyle);
Vue.use(TileLayer);
Vue.use(StyleFunc);
Vue.use(Overlay);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
