<template>
  <Layout>
    <!-- Header Box -->
    <Header>
      <CBox :width="['100%']">
        <CFlex
          w="100%"
          :justify-content="['center']"
          id="header-flex-container"
          align-items="center"
        >
          <CBox flex="1" max-width="600px" id="ais-search-container">
            <CText :display="['none', 'block']" color="white" pb="1">{{
              label("search_ws")
            }}</CText>
            <ais-search-box :placeholder="label('search_placeholder')" />
          </CBox>
          <CBox
            :pl="5"
            :display="['block', 'block', 'block', 'none']"
            width="50px"
            id="mobile-drawer-button-container"
          >
            <MobileFilterDrawer />
          </CBox>
        </CFlex>
      </CBox>
    </Header>

    <!-- End Header Box -->
    <CBox
      width="100%"
      max-width="1440px"
      height="100vh"
      overflow="hidden"
      :mt="2"
    >
      <CBox :height="['100%']">
        <CFlex :mx="4" :mt="2" justify="space-between" width="100%">
          <CFlex justify="space-between" :width="['100%', 'auto']">
            <CBreadcrumb>
              <CBreadcrumbItem>
                <CBreadcrumbLink href="https://www.wildspecies.ca/">{{
                  label("home")
                }}</CBreadcrumbLink>
              </CBreadcrumbItem>

              <CBreadcrumbItem>
                <CBreadcrumbLink as="router-link" to="/">{{
                  label("search")
                }}</CBreadcrumbLink>
              </CBreadcrumbItem>
            </CBreadcrumb>
            <CBox :mx="4">
              <ais-stats>
                <template v-slot="{ nbHits }">
                  <CText
                    >{{ `${nbHits.toLocaleString()} ${label("results")}` }}
                  </CText>
                </template>
              </ais-stats>
            </CBox>
          </CFlex>
          <CFlex align="center">
            <graph-modal v-if="selected_year" button_label="graph_results" />
            <rank-modal-container
              opentext="Rank Definitions"
              button_label="rank_definition"
            >
              <e-n-definition-of-ranks v-if="language === 'en'" />
              <f-r-definition-of-ranks v-if="language === 'fr'" />
            </rank-modal-container>
            <DownloadResults />
          </CFlex>
        </CFlex>
        <CFlex direction="row" p="" w="100%" flex="auto" height="0px">
          <!-- Begin Accordion Container -->
          <CBox :display="{ base: 'none', lg: 'block' }">
            <filters />
          </CBox>

          <!--  End Accordion Container -->
          <CBox width="100%" height="0px" id="data-container">
            <CFlex direction="column" height="" width="100%" :pl="3">
              <ResultHeaderRow />
              <CFlex
                height="calc(100vh - 200px)"
                overflow-y="scroll"
                flex="auto"
                width="100%"
                class="no-scroll"
              >
                <CBox class="scroll" pt="2" width="100%" overflow="none">
                  <ais-state-results>
                    <template v-slot="{ results: { hits } }">
                      <div v-for="(item, index) in hits" :key="index">
                        <result-item-year
                          :year="item"
                          :years="item"
                          :status="status"
                        />
                      </div>
                      <div v-if="hits.length === 0">
                        {{ label("no_results") }}
                      </div>
                    </template>
                  </ais-state-results>
                  <CFlex align="center" justify="center" flex="1" :my="2">
                    <ais-pagination :padding="2" />
                  </CFlex>
                </CBox>
              </CFlex>
            </CFlex>
          </CBox>
        </CFlex>
      </CBox>
    </CBox>
  </Layout>
</template>

<script>
import { CFlex, CBox, CText } from "@chakra-ui/vue";
import ResultItemYear from "../components/ResultItemYear.vue";
import { labels, years } from "../config";

import { mapState } from "vuex";
import ResultHeaderRow from "../components/ResultHeaderRow.vue";
import Header from "../components/Header.vue";
import Filters from "../components/Filters.vue";
import MobileFilterDrawer from "../components/MobileFilterDrawer.vue";
import DownloadResults from "../components/DownloadResults.vue";
import RankModalContainer from "../components/RankModalContainer.vue";
// import GraphResults from "../components/GraphResults.vue";
import GraphModal from "../components/GraphModal.vue";

// import DownloadCompleteResults from '../components/DownloadCompleteResults.vue'
import ENDefinitionOfRanks from "../views/ENDefinitionOfRanks.vue";
import FRDefinitionOfRanks from "../views/FRDefinitionOfRanks.vue";

export default {
  name: "Home",
  components: {
    DownloadResults,
    ResultItemYear,
    CText,
    CFlex,
    CBox,
    ResultHeaderRow,
    Header,
    Filters,
    MobileFilterDrawer,
    RankModalContainer,
    GraphModal,
    ENDefinitionOfRanks,
    FRDefinitionOfRanks,
  },
  props: {
    query_language: {
      default: "",
      type: String,
    },
  },
  mounted() {
    this.setLanguage();
  },
  methods: {
    sorted(years) {
      const sorted = years;
      return sorted.sort((a, b) => a.year - b.year).reverse();
    },

    label(label) {
      return this.labels[label][this.language];
    },

    setLanguage() {
      const lg = navigator.language;
      const browser_language_code = lg.substring(0, 2) === "fr" ? "fr" : "en";
      const language_code =
        this.query_language === "en" || this.query_language === "fr"
          ? this.query_language
          : browser_language_code;
      if (!this.query_language) {
        window.location.href = `/#/${language_code}`;
      }
      this.$store.dispatch("setLanguage", language_code);
    },
  },

  data() {
    return {
      years: years,
      labels: labels,
    };
  },
  computed: {
    ...mapState(["language", "selected_year", "selected_region"]),

    // current or historical year selected:
    //. TODO: Move to vuex store
    status() {
      const year = years.find((year) => year.label == this.selected_year);
      const status = year?.status;
      return status;
    },
  },
};
</script>

<style>
.ais-RefinementList-count {
  display: none;
}

/* body ::-webkit-scrollbar {
        display: none;
    } */

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
